import { FC } from 'react';
import { classMap } from '../util/class-map';

export interface DividerProps {
    color?: 'primary' | 'white';
}
export const Divider: FC<DividerProps> = ({ color = 'primary' }) => (
    <hr
        className={classMap(
            { 'border-white': color === 'white', 'border-orange-500': color === 'primary' },
            'w-16',
            'border-2',
            'mx-auto',
        )}
    />
);
