export interface MobileTogglerProps {
    onClick: () => void;
}

export const MobileToggler = ({ onClick }: MobileTogglerProps) => {
    return (
        <div className="p-2 space-y-2" onClick={onClick}>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
        </div>
    );
};
