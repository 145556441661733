import React, { FC, ReactNode } from 'react';
import { Divider } from '../../components/divider';
import { LearnMoreButton } from '../../widgets/learn-more-button';

type AboutSectionProps = Record<string, unknown>;
interface AboutTextProps {
    children?: ReactNode;
    className?: string;
}
const AboutText: FC<AboutTextProps> = (props: AboutTextProps) => (
    <p className={`mb-2 ${props.className}`.trim()}>{props.children}</p>
);

export const AboutSection = React.forwardRef<HTMLElement>((_props: AboutSectionProps, ref) => {
    return (
        <section ref={ref} className="py-16" id="about">
            <div className="flex flex-col items-center justify-center max-w-3xl mx-auto space-y-6 text-center">
                <h2 className="text-3xl">What is Fascia Stretch Therapy?</h2>
                <Divider />
                <AboutText>
                    Fascial Stretch Therapy<sup>TM</sup> is a pain-free, unique, complete, and complementary system of
                    table-based assisted stretching, focusing on the fascia and joint capsule as the key elements in
                    achieving optimal flexibility, strength, performance, and pain relief. FST
                    <sup>TM</sup> is one of the quickest, most effective, and most pleasant methods to eliminate pain,
                    restore function, move and feel better in activities of life, fitness, and sports!
                </AboutText>
                <AboutText>
                    FST<sup>TM</sup> targets fascia, the most prevalent tissue in the body. Fascia is the &quot;silver
                    skin&quot; interwoven between everything in our bodies, muscles, joints, bones, organs, the brain
                    and even the spine! Stretching reduces pressure and releases stored stress (adhesions) in the
                    fascia. This improves circulation, flexibility, and range of motion.
                </AboutText>
                <AboutText>
                    The key to unlocking your fascia is traction – which creates space in your joints to help them move
                    better and PNF (proprioceptive neuromuscular facilitation) to allow for a deeper stretch without
                    causing any pain.
                </AboutText>
                {/* <AboutText className="mt-5">Ready to get started?</AboutText> */}

                <LearnMoreButton />
                {/* <ScheduleButton primary /> */}
            </div>
        </section>
    );
});

AboutSection.displayName = 'AboutSection';
