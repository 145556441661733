import React, { useState, useCallback, ReactElement, FC } from 'react';
import { classMap } from '../../util/class-map';
import { HeaderMenuContext } from './header-menu-context';
import { HeaderMenuItem } from './header-menu-item';
import { MobileToggler } from './widgets/mobile-toggler';

interface HeaderMenuProps {
    children: ReactElement[];
    className?: string;
    activeIndex: number;
}

export const HeaderMenu: FC<HeaderMenuProps> = ({ children, activeIndex }) => {
    if (!children.every((child) => child.type === HeaderMenuItem)) {
        throw new Error('HeaderMenu children must be of type HeaderMenuItem');
    }

    const [showMenu, setShowMenu] = useState(false);
    const [activeLink, _setActiveLink] = useState(activeIndex);

    const setActiveLink = useCallback((value: number) => _setActiveLink(value), []);
    const toggleMobileMenu = useCallback(() => setShowMenu((p) => !p), []);

    return (
        <HeaderMenuContext.Provider
            value={{
                activeLink: activeLink,
                mobileMenuActive: showMenu,
                setActiveLink,
                toggleMobileMenu,
            }}
        >
            <div className="hidden md:block">{children}</div>
            <div className="block md:hidden">
                <MobileToggler onClick={toggleMobileMenu} />
            </div>
            <div className={classMap({ showMenuNav: showMenu, hideMenuNav: !showMenu })}>
                <div
                    className="absolute top-0 right-0 px-8 py-8"
                    onClick={toggleMobileMenu} // change isNavOpen state to false to close the menu
                >
                    <svg
                        className="w-8 h-8 text-gray-600"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </div>
                <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
                    {children.map((child, idx) => (
                        <li key={idx} className="my-4 uppercase border-b border-gray-400" onClick={toggleMobileMenu}>
                            {child}
                        </li>
                    ))}
                </ul>
            </div>
        </HeaderMenuContext.Provider>
    );
};
