/* eslint-disable @typescript-eslint/no-explicit-any */
export function classMap(arg: Record<string, any>, ...strings: string[]): string;
export function classMap(arg: string, ...strings: string[]): string;
export function classMap(arg: string | Record<string, any>, ...strings: string[]): string {
    if (typeof arg !== 'string') {
        Object.entries(arg).forEach(([key, value]) => {
            if (value) {
                strings.push(key);
            }
        });
    } else {
        strings.unshift(arg);
    }

    return strings.join(' ');
}
