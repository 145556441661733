import './button.scss';

import { FC, ReactNode } from 'react';
import { classMap } from '../../util/class-map';

interface ButtonProps {
    children: ReactNode;
    onClick?: () => void;
    link?: string;
    color?: 'primary' | 'secondary';
    outline?: boolean;
}

export const Button: FC<ButtonProps> = ({ children, onClick, link, outline, color = 'primary' }) => {
    return onClick == null ? (
        <a
            className={classMap({ [`outline-${color}`]: outline }, 'btn', 'uppercase', 'cursor-pointer', color)}
            href={link}
        >
            {children}
        </a>
    ) : (
        <button
            className={classMap({ [`outline-${color}`]: outline }, 'btn', 'uppercase', 'cursor-pointer', color)}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
