import './footer.css';

import React from 'react';

type FooterProps = Record<string, unknown>;

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    return (
        <footer className="py-6 bg-gray-100">
            <div className="container mx-auto">
                <div className="text-sm text-center text-gray-500">
                    Copyright © 2020 - Beth Reyes <br />
                    <a href="https://www.thenerdbiker.com" target="_blank" rel="noreferrer" className="block mt-6">
                        <span className="text-orange-500">Developed</span> by{' '}
                        <span className="text-orange-500">William Hexberg</span>
                    </a>
                </div>
            </div>
        </footer>
    );
};

Footer.displayName = 'Footer';
