import { ReactElement, useEffect, useRef } from 'react';

import { AboutSection, BenefitsSection, ContactSection, Footer } from './components';
import { Button } from './components/button/button';
import { HeaderMenu } from './components/header/header-menu';
import { HeaderMenuItem } from './components/header/header-menu-item';
import { Logo } from './components/logo';
import { useScrollSpy } from './hooks/use-scroll-spy';

const navbarCollapse = () => {
    const mainNav = document.getElementById('mainNav');
    if (mainNav) {
        if (window.pageYOffset > 100) {
            mainNav.classList.add('navbar-scrolled');
        } else {
            mainNav.classList.remove('navbar-scrolled');
        }
    }
};

export const App = (): ReactElement => {
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const activeLink = useScrollSpy({
        activeSectionDefault: 0,
        offsetPx: -(window.innerHeight / 2),
        sectionElementRefs: sectionRefs,
        throttleMs: 100,
    });

    window.addEventListener('scroll', navbarCollapse);
    useEffect(() => navbarCollapse(), []);

    return (
        <div className="pt-[4.75rem]" id="page-top">
            <nav className="fixed top-0 left-0 right-0 z-10 bg-white shadow-lg ">
                <div className="container flex items-center justify-between p-4 mx-auto">
                    <Logo />
                    <HeaderMenu activeIndex={activeLink}>
                        <HeaderMenuItem activeIndex={1} to="#about" text="About"></HeaderMenuItem>
                        <HeaderMenuItem activeIndex={2} to="#benefits" text="Benefits"></HeaderMenuItem>
                        <HeaderMenuItem activeIndex={4} to="#contact" text="Contact"></HeaderMenuItem>
                    </HeaderMenu>
                </div>
            </nav>
            <header className="flex items-center justify-center bg-masthead h-custom">
                <div className="container px-12 space-y-8 text-center md:px-0 md:text-left h-100">
                    <h1 className="max-w-3xl text-4xl font-bold text-white uppercase sm:text-5xl lg:text-6xl">
                        <div>
                            Noteworthy <span className="text-orange-500">results</span>.
                        </div>
                        <div>
                            Exceptional <span className="text-orange-500">relief</span>.
                        </div>
                        <div>
                            Budget <span className="text-orange-500">price</span>.
                        </div>
                    </h1>
                    <div className="flex flex-col justify-center space-y-6 sm:space-y-0 md:justify-start sm:space-x-3 sm:flex-row">
                        <Button link="#contact">Contact Me</Button>
                        <Button color="primary" outline link="#about">
                            Learn About FST
                        </Button>
                    </div>
                </div>
                <div className="absolute flex items-center justify-center w-10 h-10 p-2 bg-white rounded-full shadow-lg top-[75vh] animate-bounce dark:bg-slate-800 ring-1 ring-slate-900/5 dark:ring-slate-200/20">
                    <Button link={'#about'}>
                        <svg
                            className="w-6 h-6 text-white"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                        </svg>
                    </Button>
                </div>
            </header>
            <AboutSection ref={sectionRefs[1]} />
            <BenefitsSection ref={sectionRefs[2]} />
            {/* <CallToActionSection ref={sectionRefs[3]} /> */}
            <ContactSection ref={sectionRefs[4]} />
            <Footer />
        </div>
    );
};
