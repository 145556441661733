import { useEffect, useState } from 'react';
import { throttle } from '../util/throttle';

export interface UseScrollSpyParams {
    activeSectionDefault?: number;
    offsetPx?: number;
    sectionElementRefs: React.RefObject<HTMLElement>[];
    throttleMs?: number;
}

export const useScrollSpy = ({
    activeSectionDefault = 0,
    offsetPx = 0,
    sectionElementRefs = [],
    throttleMs = 100,
}: UseScrollSpyParams): number => {
    const [activeSection, setActiveSection] = useState(activeSectionDefault);

    const handleScroll = throttle(() => {
        let currentSectionId = activeSection;

        for (let i = 0; i < sectionElementRefs.length; i++) {
            const section = sectionElementRefs[i].current;

            // Needs to be a valid DOM Element
            if (!section || !(section instanceof Element)) {
                continue;
            }

            // GetBoundingClientRect returns values relative to viewport
            if (section.getBoundingClientRect().top + offsetPx < 0) {
                currentSectionId = i;
                continue;
            }

            // No need to continue loop, if last element has been detected
            break;
        }
        setActiveSection(currentSectionId);
    }, throttleMs);

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll, sectionElementRefs, offsetPx]);

    return activeSection;
};
