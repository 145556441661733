import React, { useState } from 'react';
import { Button } from '../components/button';

export const LearnMoreButton = (): React.ReactElement => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button onClick={handleShow}>Watch the video</Button>
            {show ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center m-0 overflow-x-hidden overflow-y-auto"
                        onClick={handleClose}
                        style={{ margin: 0 }}
                    >
                        <div className="w-3/4 h-full max-h-[95vh]" onClick={(e) => e.stopPropagation()}>
                            {/*content*/}
                            <div className="flex flex-col w-full h-full bg-white border-0 rounded-lg">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-2xl font-semibold">Learn about Fascia Stretch Therapy</h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0"
                                        onClick={handleClose}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-transparent">×</span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="h-full">
                                    <iframe
                                        src="https://www.youtube.com/embed/BJ-SUfszSRg"
                                        title="YouTube video player"
                                        className="h-full mx-auto aspect-video"
                                        // style={{ aspectRatio: '16/9' }}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    ></iframe>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    <Button onClick={handleClose}>Close</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-50" style={{ margin: 0 }}></div>
                </>
            ) : null}
        </>
    );
};
