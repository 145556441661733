import { createContext, useContext } from 'react';
import { noop } from '../../util/noop';

export const HeaderMenuContext = createContext<{
    activeLink: number;
    mobileMenuActive: boolean;
    toggleMobileMenu: () => void;
    setActiveLink: (idx: number) => void;
}>({ activeLink: 0, mobileMenuActive: false, toggleMobileMenu: noop, setActiveLink: noop });

export const useHeaderMenuContext = () => {
    const context = useContext(HeaderMenuContext);

    if (!context) {
        throw new Error('Error creating the context');
    }

    return context;
};
