import { FC, ReactElement } from 'react';
import { useHeaderMenuContext } from './header-menu-context';

export interface HeaderMenuItemProps {
    activeIndex: number;
    text: string;
    to: string;
}

export const HeaderMenuItem: FC<HeaderMenuItemProps> = ({
    activeIndex,
    text,
    to,
}: HeaderMenuItemProps): ReactElement => {
    const context = useHeaderMenuContext();

    return context.activeLink === activeIndex ? (
        <a href={to} className="py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold ">
            {text}
        </a>
    ) : (
        <a
            href={to}
            className="py-4 px-2 text-gray-500 border-b-4 border-transparent font-semibold hover:text-orange-500 transition duration-300"
        >
            {text}
        </a>
    );
};
