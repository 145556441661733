import React from 'react';
import { Button } from '../button';
import { Divider } from '../divider';

export const CallToActionSection = React.forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <section ref={ref} className="py-16 text-white bg-neutral-700">
            <div className="container flex flex-col mx-auto space-y-6 text-center">
                <h2 className="text-3xl">Relax. Get Results. Find Relief.</h2>
                <Divider />
                <div className="p-8">
                    <Button link="#contact">Contact me Now</Button>
                </div>
            </div>
        </section>
    );
});

CallToActionSection.displayName = 'CallToActionSection';
