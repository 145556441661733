import React from 'react';
import { Divider } from '../../components/divider';
// import { Benefit, BenefitsArray } from "./benefits-array";

type BenefitsSectionProps = Record<string, unknown>;

export const BenefitsSection = React.forwardRef<HTMLElement>((props: BenefitsSectionProps, ref) => {
    return (
        <section ref={ref} className="text-white bg-orange-500 " id="benefits">
            <div className="max-w-3xl p-16 mx-auto space-y-6">
                <h2 className="text-3xl text-center text-white ">Benefits of FST</h2>
                <Divider color="white" />
                <div className="flex flex-column flex-sm-row">
                    <ul style={{ listStyle: 'none' }} className="w-1/2">
                        <li className="m-3 text-center text-white text-sm-right h5">Increase range of motion</li>
                        <li className="m-3 text-center text-white text-sm-right h5">Improve muscle activation</li>
                        <li className="m-3 text-center text-white text-sm-right h5">Reduce chance of injury</li>
                    </ul>
                    <ul style={{ listStyle: 'none' }} className="w-1/2">
                        <li className="m-3 text-center text-white text-sm-left h5">Improve flexibility</li>
                        <li className="m-3 text-center text-white text-sm-left h5">Improve posture</li>
                        <li className="m-3 text-center text-white text-sm-left h5">Relieve chronic pain</li>
                    </ul>
                </div>
            </div>
        </section>
    );
});

BenefitsSection.displayName = 'BenefitsSection';
