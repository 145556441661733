import './contact.css';
import React from 'react';
import { Divider } from '../divider';

export const ContactSection = React.forwardRef<HTMLElement>((props, ref) => {
    // const phone = "555-555-5555";
    const email = 'stretchwithbeth@gmail.com';
    const facebook = 'https://www.facebook.com/Foothill-Fitness-Studio-1715318782076633/';
    const instagram = 'https://www.instagram.com/foothill_fitness/';
    return (
        <section ref={ref} className="py-16" id="contact">
            <div className="container mx-auto space-y-6">
                <div className="text-center col-lg-8">
                    <h2 className="text-3xl">Let&apos;s Get In Touch!</h2>
                </div>
                <Divider />
                <div className="flex items-center justify-center w-3/5 mx-auto space-x-6 text-orange-500 ">
                    <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
                        <i className="text-gray-500 fas fa-envelope fa-2x"></i>
                    </a>
                    <a target="_blank" rel="noreferrer" href={facebook}>
                        <i className="text-gray-500 fab fa-facebook fa-2x"></i>
                    </a>
                    <a target="_blank" rel="noreferrer" href={instagram}>
                        <i className="text-gray-500 fab fa-instagram fa-2x"></i>
                    </a>
                </div>
            </div>
        </section>
    );
});

ContactSection.displayName = 'ContactSection';
