import { FC, ReactElement } from 'react';

export const Logo: FC = (): ReactElement => {
    return (
        <a
            href="#page-top"
            className="border-orange-400 text-gray-500 rounded p-2 border-2 hover:text-orange-400 font-bold"
        >
            StretchWithBeth
        </a>
    );
};
